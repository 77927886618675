import { Container, TabContainer, TabContent, TabPane, Col, Row, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import projImg1 from "../assets/img/project-imgv1_v1.png";
import projImg2 from "../assets/img/project-imgv2_v2.jpg";
import projImg3 from "../assets/img/project-imgv3_v3.png";
import projImg4 from "../assets/img/project-imgv4_v4.jpg";
import projImg5 from "../assets/img/project-imgv5_v5.jpg";
import projImg6 from "../assets/img/project-imgv6_v6.jpg";
import projImg7 from "../assets/img/project-imgv7.jpg";
import projImg8 from "../assets/img/project-imgv8.jpg";
import projImg9 from "../assets/img/project-imgv9.jpg";
import TrackVisibility from "react-on-screen";
import {isVisible} from "@testing-library/user-event/dist/utils";


export const Projects = () => {

    const projects = [
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg1,
        },
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg2,
        },
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg3,
        },];
    const projects2 = [
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg4,
        },
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg5,
        },
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg6,
        },];
    const projects3 = [
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg7,
        },
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg8,
        },
        {
            title: "Business Startup",
            description: "Development",
            imgUrl: projImg9,
        },
    ];
    return (
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col>
                        <TrackVisibility>
                            {({ isVisible }) =>
                            <div className={isVisible ? "animate__animated animate__bounce" : ""}>
                        <h2>Projects</h2>
                        <p>This is about projects</p>
                            </div>}
                        </TrackVisibility>
                        <TabContainer id="projects-tabs" defaultActiveKey="first">
                        <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Tab One</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Tab Two</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Tab Three</Nav.Link>
                            </Nav.Item>
                        </Nav>
                            <TabContent>
                                <TabPane eventKey="first">
                                    <Row>
                                        {
                                            projects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </TabPane>
                                <TabPane eventKey="second">
                                    <Row>
                                        {
                                            projects2.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </TabPane>
                                <TabPane eventKey="third">
                                    <Row>
                                        {
                                            projects3.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </TabPane>

                            </TabContent>
                        </TabContainer>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>

    )
}