import {Col, Container, Row} from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                    <Col sm={6}>
                        <img src={logo} alt="Logo" />
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <br/>
                            <br/>
                            <a href="https://www.linkedin.com/in/alexandru-g%C4%83inu%C8%99%C4%83-72073980/"><img src={navIcon1} /></a>
                            <a href="https://github.com/Alexandrugainusa"><img src={navIcon2} /></a>
                            <a href="https://www.myresume.vathos.ro"><img src={navIcon3} /></a>
                        </div>
                        <p>Copyright 2023. All right Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}